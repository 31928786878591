import * as React from 'react'
import Layout from '../components/layout/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import { header, container, h1, row, column } from '../styles/about.module.css'

const AboutPage = () => {
  return (
    <Layout>
      <header className={header}>
        <div className={container}>
          <h1 className={h1}>About</h1>
        </div>
      </header>
      <div className={container}>
        <p>Hi!, I'm <strong>David Birchall</strong>, I grew up in England and now live in Canada and I love to make things. A frontend developer by trade, I spend my work life designing and building websites.</p>
        <h2>Hobbies</h2>
        <p>I love designing and making things, whether it's creating websites, learning to draw and paint in Photoshop, creating models, building Lego or a good DIY project around the house.</p>
        <p>When I'm not busy making things you will normally find me spending time with my amazing wife and daughter, taking our dog Bandit on walks, watching my Football team Manchester United play and occasionally playing video and board games.</p>
        
        <div className={row}> 
          <div className={column}>
            <StaticImage aspectRatio={2/3} width={400} src="../images/davidbirchall1.jpg" alt="David Birchall" />
            <StaticImage aspectRatio={3/2} width={400} src="../images/davidbirchall2.jpg" alt="David Birchall" />
          </div>
          <div className={column}>
            <StaticImage aspectRatio={3/2} width={400} src="../images/davidbirchall3.jpg" alt="David Birchall" />
            <StaticImage aspectRatio={2/3} width={400} src="../images/davidbirchall4.jpg" alt="David Birchall" />
          </div> 
          <div className={column}>
            <StaticImage aspectRatio={2/3} width={400} src="../images/davidbirchall5.jpg" alt="David Birchall" />
            <StaticImage aspectRatio={3/2} width={400} src="../images/davidbirchall6.jpg" alt="David Birchall" />
          </div>
          <div className={column}>
            <StaticImage aspectRatio={3/2} width={400} src="../images/davidbirchall7.jpg" alt="David Birchall" />
            <StaticImage aspectRatio={2/3} width={400} src="../images/davidbirchall8.jpg" alt="David Birchall" />
          </div>
        </div> 
      </div>
    </Layout>
  )
}

export default AboutPage